<template>
  <div class="page">
    <div class="sub-page">
      <div id="teacher">
        <div class="title-box">
          ความคิดเห็นของครู
        </div>

        <template v-for="teacherOpinion in teacherDataSource" :key="teacherOpinion.id">
          <div class="title-term">ภาคเรียนที่ {{ teacherOpinion.term.termNo }}</div>
          <p>...{{ teacherOpinion.comment }}...</p>
          <div>
            <div><span>ลงชื่อ</span>.............................</div>
            <div>( {{ teacherOpinion.authorName }} )</div>
            <div>ครูประจำชั้น</div>
          </div>
        </template>
      </div>
      <div id="director">
        <div class="title-box">
          ความคิดเห็นของผู้บริหาร
        </div>
        <template v-for="directorOpinion in directorDataSource" :key="directorOpinion.id">
          <div class="title-term">ภาคเรียนที่ {{ directorOpinion.term.termNo }}</div>
          <p>...{{ directorOpinion.comment }}...</p>
          <div>
            <div><span>ลงชื่อ</span>.............................</div>
            <div>( {{ directorOpinion.authorName }} )</div>
            <div>ผู้บริหารสถานศึกษา</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  props: { studentId: Number },
  setup(props) {
    const { state, getters } = useStore();
    const records = computed(() => state.opinion.currentRecords);
    const student = computed(() => getters.getStudentById(props.studentId));
    const schoolYear = computed(() =>
      state.schoolYear.data.find(schoolYear => schoolYear.id == student.value.schoolYearId),
    );
    const terms = computed(() => schoolYear.value.terms);

    const teacherDataSource = computed(() => {
      if (!records.value) return [];
      return records.value
        .filter(record => record.authorRole == 'TEACHER' && record.studentId == props.studentId)
        .map(record => {
          const term = terms.value.find(term => term.id == record.schoolTermId);
          if (!term) {
            return null;
          }
          return {
            ...record,
            term,
          };
        });
    });
    const directorDataSource = computed(() => {
      if (!records.value) return [];
      return records.value
        .filter(record => record.authorRole == 'DIRECTOR' && record.studentId == props.studentId)
        .map(record => {
          const term = terms.value.find(term => term.id == record.schoolTermId);
          if (!term) {
            return null;
          }
          return {
            ...record,
            term,
          };
        });
    });

    return {
      teacherDataSource,
      directorDataSource,
    };
  },
};
</script>

<style scoped>
.title-box {
  width: 5cm;
  padding: 5px 10px 5px 10px;
  margin: 20px auto 20px auto;
  border: 2px solid black;
}

.title-term {
  font-weight: bold;
}

.role-title {
  margin: 20px;
}

p {
  margin: 10px;
}
</style>
