<template>
  <div class="page">
    <div class="subpage" v-if="classroom">
      <div>
        <img v-if="schoolInfos.logoUrl" :src="schoolInfos.logoUrl" class="logo-img" />
        <img
          v-else
          src="https://firebasestorage.googleapis.com/v0/b/rathakul-backoffice.appspot.com/o/school_logos%2Fdla_logo.jpg?alt=media&token=83f179e6-fbd6-458b-bbd4-2a43c0b18e2e"
          class="logo-img"
        />
      </div>
      <div class="cover-title" :style="{ 'margin-top': '10mm' }">
        สมุดรายงานประจำตัวเด็กปฐมวัย
      </div>
      <div class="cover-content">
        <div>ชั้น {{ getGradeLevelTitle(classroom.gradeLevel) }} ห้อง {{ classroom.title }}</div>
        <div :style="{ 'margin-top': '3mm' }">ปีการศึกษา {{ schoolYear.year }}</div>
        <div :style="{ 'margin-top': '5mm' }">สถานศึกษา</div>
        <div :style="{ 'margin-top': '3mm' }">...{{ schoolInfos.title }}...</div>
        <div :style="{ 'margin-top': '5mm' }">สังกัด ...{{ nullSafer(schoolInfos.educationalServiceArea) }}...</div>
        <div :style="{ 'margin-top': '3mm' }">{{ getAddressLine(schoolInfos.address) }}</div>
      </div>

      <div class="cover-content" :style="{ 'margin-top': '3cm' }">
        <div class="cover-infos">
          <span>ชื่อ - สกุล </span> ....{{ prefixName(student) }} {{ student.firstname }} {{ student.lastname }}....
          <span>ชื่อเล่น</span> ....{{ student.nickname ? student.nickname : '-' }}....
        </div>

        <div class="cover-infos">
          <span>เลขประจำตัว</span> ....{{ student.studentCode }}.... <span>เลขบัตรประชาชน</span> ....{{
            student.citizenId ? student.citizenId : '-'
          }}....
        </div>

        <div class="cover-infos">
          <span>เกิดวันที่</span> ....{{ fulldateString(student.dateOfBirth) }}.... <span>อายุ</span> ....{{
            getStudentAgeString(student)
          }}....
        </div>

        <div class="cover-infos">
          <span>ชื่อครูประจำชั้น</span>
          ....{{ userInfos.firstname }} {{ userInfos.lastname }}....
        </div>

        <div class="cover-infos"><span>ชื่อผู้บริหารสถานศึกษา</span> .......................................</div>
      </div>
    </div>
    <a-spin :spinning="true" v-else>
      <div class="subpage"></div>
    </a-spin>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import {
  fulldateString,
  getStudentAgeString,
  getGradeLevelTitle,
  nullSafer,
  getAddressLine,
} from '@/components/utilities/utilities';

const prefixName = student => {
  return student.gender == 'MALE' ? 'เด็กชาย' : 'เด็กหญิง';
};

export default {
  props: { studentId: Number },
  setup(props) {
    const { state, getters } = useStore();

    const student = computed(() => getters.getStudentById(props.studentId));
    const classroomId = computed(() => {
      if (!student.value) return null;
      return student.value.classroomId;
    });

    const classroom = computed(() => {
      if (!state.classroom.classrooms) return null;
      const found = state.classroom.classrooms.find(item => item.id == classroomId.value);
      if (!found) return null;
      return found;
    });

    const schoolYear = computed(() => state.schoolYear.selected);
    const schoolInfos = computed(() => state.school.infos);
    const userInfos = computed(() => getters.userProfile());

    return {
      schoolYear,
      schoolInfos,
      classroom,
      getGradeLevelTitle,
      nullSafer,
      userInfos,
      getAddressLine,
      prefixName,
      student,
      fulldateString,
      getStudentAgeString,
    };
  },
};
</script>
>
