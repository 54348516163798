<template>
  <div class="page">
    <div class="sub-page">
      <div class="page-top-title">
        บันทึกพัฒนาการด้านร่างกาย
      </div>
      <div>
        <table width="100%">
          <thead>
            <tr>
              <td rowspan="2" width="28%">รายการ</td>
              <td colspan="4">ภาคเรียนที่ 1</td>
              <td colspan="4">ภาคเรียนที่ 2</td>
            </tr>
            <tr>
              <td width="9%">ครั้งที่ 1</td>
              <td width="9%">ระดับคุณภาพ</td>
              <td width="9%">ครั้งที่ 2</td>
              <td width="9%">ระดับคุณภาพ</td>
              <td width="9%">ครั้งที่ 1</td>
              <td width="9%">ระดับคุณภาพ</td>
              <td width="9%">ครั้งที่ 2</td>
              <td width="9%">ระดับคุณภาพ</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in bodymassDataSource" :key="index">
              <td v-for="(col, cIndex) in row" :key="`c${cIndex}`">
                {{ col }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="remark">
          <span class="remark">หมายเหตุ</span>
          <ul>
            <li>ระดับคุณภาพ 3 หมายถึง ปกติ / เป็นไปตามเกณฑ์มาตรฐาน</li>
            <li>ระดับคุณภาพ 2 หมายถึง ค่อนข้างปกติ / ค่อนข้างมาก - สูง หรือค่อนข้างน้อยกว่าเกณฑ์มาตรฐาน</li>
            <li>ระดับคุณภาพ 1 หมายถึง ไม่ปกติ ควรส่งเสริม / มาก - สูง หรือ น้่อยกว่าเกณฑ์มาตรฐาน</li>
          </ul>
        </div>
      </div>

      <div class="page-top-title" :style="{ 'margin-top': '40px' }">
        เวลามาเรียน (คิดเป็นวัน)
      </div>
      <div>
        <table width="100%">
          <thead>
            <tr>
              <td>ภาคเรียน / เวลาเรียน</td>
              <td>เวลาเรียนเต็ม</td>
              <td>มาเรียน</td>
              <td>ไม่มาเรียน</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(attendance, index) in attendanceDataSource" :key="index">
              <td>{{ attendance.title }}</td>
              <td>{{ attendance.total }}</td>
              <td>{{ attendance.present }}</td>
              <td>{{ attendance.absent }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { weightToScore, heightToScore } from '@/utility/bodymassCalculator';

const nullTerm = termNo => {
  return {
    title: `ภาคเรียนที่ ${termNo}`,
    total: 0,
    present: 0,
    absent: 0,
    leave: 0,
    sick: 0,
  };
};

const getWeightRecord = (termId, evaluateNo, records) => {
  const found = records.find(rec => rec.schoolTermId == termId && rec.evaluateNo == evaluateNo);
  if (!found) return 0;
  return found.weight;
};

const getWeightScore = (termId, evaluateNo, records, student) => {
  const weight = getWeightRecord(termId, evaluateNo, records);
  return weightToScore(weight, student);
};

const getHeightRecord = (termId, evaluateNo, records) => {
  const found = records.find(rec => rec.schoolTermId == termId && rec.evaluateNo == evaluateNo);
  if (!found) return 0;
  return found.height;
};

const getHeightScore = (termId, evaluateNo, records, student) => {
  const height = getHeightRecord(termId, evaluateNo, records);
  return heightToScore(height, student);
};

export default {
  name: 'BodymassAndAttendancePage',
  props: { studentId: Number },
  setup(props) {
    const { state, getters } = useStore();

    const student = computed(() => getters.getStudentById(props.studentId));
    const attendanceReports = computed(() => state.print.attendanceReport);
    const bodymassRecords = computed(() => state.bodymass.currentRecords);

    const schoolYear = computed(() =>
      state.schoolYear.data.find(schoolYear => schoolYear.id == student.value.schoolYearId),
    );

    const term1 = computed(() => schoolYear.value.terms.find(term => term.termNo == 1));
    const term2 = computed(() => schoolYear.value.terms.find(term => term.termNo == 2));

    const attendanceDataSource = computed(() => {
      const found = attendanceReports.value.find(item => item.studentId == props.studentId);
      if (!found) return null;

      const { termly, yearly } = found;

      const term1Data = termly.find(termItem => termItem.termId == term1.value.id) ?? nullTerm(1);
      const term2Data = termly.find(termItem => termItem.termId == term2.value.id) ?? nullTerm(2);

      return [
        {
          title: 'ภาคเรียนที่ 1',
          total: term1Data.total,
          present: term1Data.present,
          absent: Number(term1Data.sick) + Number(term1Data.absent) + Number(term1Data.leave),
        },
        {
          title: 'ภาคเรียนที่ 2',
          total: term2Data.total,
          present: term2Data.present,
          absent: Number(term2Data.sick) + Number(term2Data.absent) + Number(term2Data.leave),
        },
        {
          title: 'ตลอดปี',
          total: yearly.total,
          present: yearly.present,
          absent: Number(yearly.sick) + Number(yearly.absent) + Number(yearly.leave),
        },
      ];
    });

    const bodymassDataSource = computed(() => {
      if (!bodymassRecords.value) return [];

      const records = bodymassRecords.value.filter(record => record.studentId == props.studentId);

      const weights = [
        'น้ำหนัก (กก.)',
        getWeightRecord(term1.value.id, 1, records),
        getWeightScore(term1.value.id, 1, records, student.value),
        getWeightRecord(term1.value.id, 2, records),
        getWeightScore(term1.value.id, 2, records, student.value),
        getWeightRecord(term2.value.id, 1, records),
        getWeightScore(term2.value.id, 1, records, student.value),
        getWeightRecord(term2.value.id, 2, records),
        getWeightScore(term2.value.id, 2, records, student.value),
      ];

      const heights = [
        'ส่วนสูง (ซม.)',
        getHeightRecord(term1.value.id, 1, records),
        getHeightScore(term1.value.id, 1, records, student.value),
        getHeightRecord(term1.value.id, 2, records),
        getHeightScore(term1.value.id, 2, records, student.value),
        getHeightRecord(term2.value.id, 1, records),
        getHeightScore(term2.value.id, 1, records, student.value),
        getHeightRecord(term2.value.id, 2, records),
        getHeightScore(term2.value.id, 2, records, student.value),
      ];

      return [weights, heights];
    });

    console.log('bodymass', bodymassRecords);

    return {
      attendanceDataSource,
      bodymassDataSource,
    };
  },
};
</script>

<style scoped>
thead tr td {
  font-size: 1.2em;
  font-weight: bold;
}

thead tr {
  height: 70px;
}

td.title {
  text-align: left;
}

div.remark {
  margin-top: 20px;
  text-align: left;
}

span.remark {
  font-weight: bold;
}
</style>
