<template>
  <PrintWrapper>
    <CoverPage :studentId="studentId" />
    <BodymassAndAttendancePage :studentId="studentId" v-if="attendanceReport" />
    <AttributeTablePage :studentId="studentId" />
    <SchoolOpinionPage :studentId="studentId" />
    <DevelopmentSummaryPage :studentId="studentId" />
    <button class="printButton" @click="print">พิมพ์เอกสาร</button>
  </PrintWrapper>
</template>

<style scoped>
.printButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #3f51b5;
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media print {
  .printButton {
    display: none;
  }
}
</style>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { PrintWrapper } from './style';

import CoverPage from './overview/Student/CoverPage.vue';
import BodymassAndAttendancePage from './overview/Student/BodymassAndAttendancePage.vue';
import AttributeTablePage from './overview/Student/AttributeTablePage.vue';
import SchoolOpinionPage from './overview/Student/SchoolOpinionPage.vue';
import DevelopmentSummaryPage from './overview/Student/DevelopmentSummaryPage.vue';

export default {
  components: {
    PrintWrapper,
    CoverPage,
    BodymassAndAttendancePage,
    AttributeTablePage,
    SchoolOpinionPage,
    DevelopmentSummaryPage,
  },
  props: {
    studentId: Number,
  },
  setup(props) {
    const { dispatch, state, getters } = useStore();

    const developmentReport = computed(() => state.print.developmentReport);
    const attendanceReport = computed(() => state.print.attendanceReport);
    const student = computed(() => getters.getStudentById(props.studentId));
    const classroomId = computed(() => {
      if (!student.value) return null;
      return student.value.classroomId;
    });

    const classroom = computed(() => {
      if (!state.classroom.classrooms) return null;
      const found = state.classroom.classrooms.find(item => item.id == classroomId.value);
      if (!found) return null;
      return found;
    });

    const schoolYear = computed(() => {
      const found = state.schoolYear.data.find(schoolYear => schoolYear.id == student.value.schoolYearId);
      return found;
    });

    const print = () => {
      window.print();
    };

    onMounted(async () => {
      console.log('==== onMounted ====');
      await dispatch('getDevelopmentReport', {
        classroomId: student.value.classroomId,
        'studentIds[]': student.value.id,
      });
      await dispatch('getAttendanceReport', {
        classroomId: student.value.classroomId,
        'studentIds[]': student.value.id,
      });
      await dispatch('getClassroomBodymassRecords', {
        schoolYearId: student.value.schoolYearId,
        classroomId: student.value.classroomId,
        query: {
          'studentIds[]': student.value.id,
        },
      });
      await dispatch('getClassroomOpinionRecords', {
        schoolYearId: student.value.schoolYearId,
        classroomId: student.value.classroomId,
        query: {
          'studentId[]': student.value.id,
        },
      });
      await dispatch('getTopicGrade', {
        schoolYearId: student.value.schoolYearId,
        classroomId: student.value.classroomId,
        studentId: student.value.id,
      });
      await dispatch('getEvaluateForm', {
        standardYear: schoolYear.value.standardYear,
        gradeLevel: classroom.value.gradeLevel,
      });
    });

    return { developmentReport, attendanceReport, print };
  },
};
</script>
