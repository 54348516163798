<template>
  <div class="page">
    <div class="sub-page">
      <div class="page-top-title">
        สรุปผลการประเมินพัฒนาการตามมาตรฐานคุณลักษณะที่พึงประสงค์การศึกษาปฐมวัย
      </div>
      <div>
        <table width="100%">
          <thead>
            <tr>
              <td rowspan="2">ลำดับที่</td>
              <td rowspan="2">มาตรฐานคุณลักษณะที่พึงประสงค์</td>
              <td rowspan="1" colspan="3">ผลการประเมินพัฒนาการ</td>
            </tr>
            <tr>
              <td>ภาคเรียนที่ 1</td>
              <td>ภาคเรียนที่ 2</td>
              <td>สรุปตลอดปีการศึกษา</td>
            </tr>
          </thead>

          <tbody>
            <tr v-for="attribute in dataSource" :key="attribute.id">
              <td>{{ attribute.order }}</td>
              <td class="title">{{ attribute.title }}</td>
              <td>{{ attribute.term1 }}</td>
              <td>{{ attribute.term2 }}</td>
              <td v-if="attribute.summary" :rowspan="attribute.summary.rowspan">
                <div>{{ attribute.summary.title }}</div>
                <div>{{ attribute.summary.score }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="remark">
        <span class="remark">หมายเหตุ</span> <span>สรุปตลอดปีการศึกษา</span>
        นำผลการประเมินเฉพาะภาคเรียนที่ 2 ในมาตรฐานฯ ของด้านพัฒนาการมารวมกัน แล้วหารด้วยจำนวนมาตรฐานฯ
        ในด้านการพัฒนาการนั้น
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref, onMounted } from 'vue';
export default {
  props: { studentId: Number },
  setup(props) {
    const { dispatch, state, getters } = useStore();
    const student = computed(() => getters.getStudentById(props.studentId));

    const classroomId = computed(() => {
      if (!student.value) return null;
      return student.value.classroomId;
    });

    const classroom = computed(() => {
      if (!state.classroom.classrooms) return null;
      const found = state.classroom.classrooms.find(item => item.id == classroomId.value);
      if (!found) return null;
      return found;
    });

    const schoolYear = computed(() =>
      state.schoolYear.data.find(schoolYear => schoolYear.id == student.value.schoolYearId),
    );

    const topicList = computed(() => state.evaluateForm.currentForm);
    const topicGrades = computed(() => state.gradeConfirmation.currentTopicGradeRecords);
    const attrScores = computed(() => state.gradeConfirmation.currentRecords);

    const dataSource = ref([]);

    const getAttributeScore = (studentId, topicId, attributeId, termNo) => {
      return attrScores.value[`student_${studentId}`][`topic_${topicId}`][`attribute_${attributeId}`][`term_${termNo}`];
    };

    const getTermId = (termNo, schoolYear) => {
      const found = schoolYear.terms.find(term => term.termNo == termNo);
      if (!found) return null;
      return found.id;
    };

    const updateDataSource = () => {
      dataSource.value = topicList.value.reduce((list, topic) => {
        const term1Id = getTermId(1, schoolYear.value);
        const term2Id = getTermId(2, schoolYear.value);

        const attributes = topic.attributes.map((att, index, array) => {
          if (index == 0) {
            let score = 0;
            if (topicGrades.value) {
              const found = topicGrades.value.find(grade => grade.developmentTopicId == topic.id);
              if (found) {
                score = found.score;
              }
            }

            return {
              id: att.id,
              title: att.title,
              order: att.order,
              term1: getAttributeScore(student.value.id, topic.id, att.id, term1Id),
              term2: getAttributeScore(student.value.id, topic.id, att.id, term2Id),
              summary: {
                title: topic.title.replace('พัฒนาการ', ''),
                rowspan: array.length,
                score,
              },
            };
          }

          return {
            id: att.id,
            title: att.title,
            order: att.order,
            term1: getAttributeScore(student.value.id, topic.id, att.id, term1Id),
            term2: getAttributeScore(student.value.id, topic.id, att.id, term2Id),
            summary: null,
          };
        });

        list = list.concat(attributes);

        return list;
      }, []);
    };

    const fetchTopicsList = () => {
      return new Promise((resolve, reject) => {
        dispatch('getEvaluateForm', {
          standardYear: schoolYear.value.standardYear,
          gradeLevel: classroom.value.gradeLevel,
        })
          .then(val => {
            resolve(val);
          })
          .catch(err => {
            reject(err);
          });
      });
    };

    const fetchStudentAttributeScore = () => {
      return new Promise((resolve, reject) => {
        dispatch('getAttributeScores', {
          schoolYearId: schoolYear.value.id,
          classroomId: classroom.value.id,
          studentId: student.value.id,
        })
          .then(val => {
            resolve(val);
          })
          .catch(err => {
            reject(err);
          });
      });
    };

    const fetchStudentGrades = () => {
      return new Promise((resolve, reject) => {
        dispatch('getTopicGrade', {
          schoolYearId: student.value.schoolYearId,
          classroomId: student.value.classroomId,
          studentId: student.value.id,
        })
          .then(val => {
            resolve(val);
          })
          .catch(err => {
            reject(err);
          });
      });
    };

    onMounted(async () => {
      if (!topicList.value) {
        await fetchTopicsList();
        await fetchStudentAttributeScore();
        await fetchStudentGrades();
        updateDataSource();
      }
    });

    return {
      dataSource,
    };
  },
};
</script>

<style scoped>
thead tr td {
  font-size: 1.2em;
  font-weight: bold;
}

thead tr {
  height: 70px;
}

td.title {
  text-align: left;
}

div.remark {
  margin-top: 20px;
  text-align: left;
}

span.remark {
  font-weight: bold;
}
</style>
