<template>
  <div class="page">
    <div class="sub-page">
      <div class="page-top-title">
        ผลการประเมินความพร้อมพัฒนาการทั้ง 4 ด้าน ตลอดปีการศึกษา
      </div>
      <div>
        <table width="100%">
          <thead>
            <tr class="header">
              <td class="table-header-text" width="37%">พัฒนาการ / ผลการประเมิน</td>
              <td class="table-header-text" width="21%">
                <div>ดี</div>
                <div>3</div>
              </td>
              <td class="table-header-text" width="21%">
                <div>พอใช้</div>
                <div>2</div>
              </td>
              <td class="table-header-text" width="21%">
                <div>ควรส่งเสริม</div>
                <div>1</div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr class="body" v-for="topic in dataSource" :key="topic.id">
              <td>{{ topic.title }}</td>
              <td v-for="(score, index) in topic.scoreDatas" :key="index">
                {{ score }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  props: { studentId: Number },
  setup(props) {
    const { dispatch, state, getters } = useStore();
    const student = computed(() => getters.getStudentById(props.studentId));

    const classroomId = computed(() => {
      if (!student.value) return null;
      return student.value.classroomId;
    });

    const classroom = computed(() => {
      if (!state.classroom.classrooms) return null;
      const found = state.classroom.classrooms.find(item => item.id == classroomId.value);
      if (!found) return null;
      return found;
    });

    const schoolYear = computed(() =>
      state.schoolYear.data.find(schoolYear => schoolYear.id == student.value.schoolYearId),
    );

    const topicList = computed(() => state.evaluateForm.currentForm);
    const topicGrades = computed(() => state.gradeConfirmation.currentTopicGradeRecords);

    const dataSource = computed(() => {
      if (!topicList.value || !topicGrades.value) return null;
      return topicGrades.value.map(record => {
        const found = topicList.value.find(item => item.id == record.developmentTopicId);
        const template = [3, 2, 1];

        const scoreDatas = template.map(score => {
          return score == record.score ? '✔' : '';
        });

        if (!found) {
          return record;
        }
        return {
          ...record,
          scoreDatas,
          order: found.order,
          title: found.title,
        };
      });
    });

    const fetchTopicsList = () => {
      return new Promise((resolve, reject) => {
        dispatch('getEvaluateForm', {
          standardYear: schoolYear.value.standardYear,
          gradeLevel: classroom.value.gradeLevel,
        })
          .then(val => {
            resolve(val);
          })
          .catch(err => {
            reject(err);
          });
      });
    };

    const fetchStudentGrades = () => {
      return new Promise((resolve, reject) => {
        dispatch('getTopicGrade', {
          schoolYearId: student.value.schoolYearId,
          classroomId: student.value.classroomId,
          studentId: student.value.id,
        })
          .then(val => {
            resolve(val);
          })
          .catch(err => {
            reject(err);
          });
      });
    };

    onMounted(async () => {
      if (!topicList.value) {
        await fetchTopicsList();
      }
      await fetchStudentGrades();
    });

    return {
      student,
      dataSource,
    };
  },
};
</script>

<style scoped>
.table-header-text {
  font-size: 1.2em;
  font-weight: bold;
}

tr.header {
  height: 100px;
}

tr.body {
  height: 25px;
}
</style>
